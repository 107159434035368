import React, { useEffect, useState } from "react";
import "./Hero.css";
import TextSvg from "./assets/HeroText.svg";
import HeroSlide1 from "./assets/Hero1.png";
import HeroAbout from "./assets/HeroAbout.png";
import Button from "../Button/Button";

import heroImages from "./assets/images";
import useInterval from "../../customHooks/useInterval/useInterval";

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [maxImageLength, setMaxImageLength] = useState(
    Object.keys(heroImages).length
  );
  const [currentImage, setCurrentImage] = useState(heroImages[0]);
  const getImageWithIndex = (imageIndex) => {
    let targetImage = null;
    targetImage =
      heroImages[
        Object.keys(heroImages).find((image, index) => index == imageIndex)
      ];
    return targetImage;
  };

  function scrollTo() {}

  const handleImageIndexUpdate = () => {
    if (currentImageIndex < maxImageLength - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  };

  useEffect(() => {
    setCurrentImage(getImageWithIndex(currentImageIndex));
  }, [currentImageIndex]);

  useInterval(() => {
    handleImageIndexUpdate();
  }, 500);

  return (
    <>
      <section className="Hero">
        <div className="Hero-content">
          <div className="Hero-contentImg">
            <img src={TextSvg} alt=" " className="rotating_text"></img>
            <img src={HeroAbout} alt=" " className="dev_image "></img>
          </div>
          <div className="Hero-contentText">
            <h1 className="Hero-contentHeading"> Workfolio</h1>
            <div>
              <p className="Hero-contentPara">
                Hey there, I'm Devednya! I'm a self-taught digital product
                designer with over 5 years of creative experience. My passion
                lies in crafting solutions for complex problems
              </p>
              <div className="Hero-contentCTA">
                <Button ctaText={"View Work"}></Button>
                <Button
                  action={scrollTo}
                  type={"secondary"}
                  ctaText={"Contact me"}
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="Hero-slides">
          <img className="Hero-slidesImage" src={currentImage}></img>
        </div>
      </section>
    </>
  );
};

export default Hero;
