import React from "react";
import "./Button.css";

const Button = ({ type, size, action, ctaText }) => {
  return (
    <button
      className={[
        "customButton",
        type !== "secondary"
          ? "customButton--primary"
          : "customButton--secondary",
      ].join(" ")}
      onClick={action}
    >
      {ctaText}
    </button>
  );
};

export default Button;
