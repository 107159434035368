import React from "react";
import "./HomePage.css";
import Footer from "../../components/Footer/Footer";
import CurrentWork from "../../components/CurrentWork/CurrentWork";
import Hero from "../../components/Hero/Hero";
import Otherwork from "../../components/OtherWork/Otherwork";
import Playground from "../../components/Playground/Playground";
import Artworks from "../../components/Artworks/Artworks";
const HomePage = () => {
  return (
    <div className="HomePage">
      <Hero></Hero>
      {/* <CurrentWork></CurrentWork>
      <Otherwork></Otherwork>
      <Playground></Playground>
      <Artworks></Artworks>
      <Footer></Footer> */}
    </div>
  );
};

export default HomePage;
