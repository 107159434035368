const heroImages = {
  img_1: require("./1.png"),
  img_2: require("./2.png"),
  img_3: require("./3.png"),
  img_4: require("./4.png"),
  img_5: require("./5.png"),
  img_6: require("./6.png"),
  img_7: require("./7.png"),
  img_8: require("./8.png"),
  img_9: require("./9.png"),
};
module.exports = heroImages;
